import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from '@config/api.config';
import { AppConfig } from '@config/app.config';
import { environment } from '@environments/environment';
import { NotifyBeforeLogoutComponent } from '@modals/notify-before-logout/notify-before-logout.dialog';
import { SomethingWentWrongDialogComponent } from '@modals/something-went-wrong/something-went-wrong.dialog';
import { UserData } from '@models/dto/UserData';
import { AuthProvider } from '@providers/IAuthProvider';
import { MapCanvasService } from '@services/map-canvas.service';
import { UserService } from '@services/user.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
  useClass: BackendAuthClient,
})
export class BackendAuthClient extends AuthProvider {
  #document = inject<Document>(DOCUMENT);
  #httpClient = inject(HttpClient);
  #interval: any;
  #isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  #isAuthenticatedValue: boolean;
  #mapCanvasService = inject(MapCanvasService);
  #ngxBsModalService = inject(BsModalService);
  #nibioTokenSubject = new BehaviorSubject<string>(localStorage.getItem('nibioToken'));
  #nibioTokenValue: string | undefined;
  #router = inject(Router);
  #urlBackendService: string = ApiConfig.proxiedBackendUrl;
  #userService = inject(UserService);

  bsModalRef: BsModalRef | undefined = undefined;
  idTokenValue: string;
  override providerName$ = 'BackendAuthClient';

  get checkAuth$(): Observable<boolean> {
    if (!this.nibioToken?.length) {
      this.#isAuthenticatedSubject.next(false);
      return of(false);
    }

    const url = this.#urlBackendService + 'isUserAuthenticated';
    return this.#httpClient
      .get<IValidateTokenResponse>(url, {
        headers: new HttpHeaders({
          nibioToken: 'Bearer ' + this.nibioToken,
        }),
      })
      .pipe(
        map(response => {
          this.#isAuthenticatedSubject.next(response.isValidUserToken);
          return response.isValidUserToken;
        }),
      );
  }

  get isAuthenticated(): boolean {
    return this.#isAuthenticatedValue;
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.#isAuthenticatedSubject.asObservable();
  }

  get nibioToken(): string | undefined {
    return this.#nibioTokenValue;
  }

  get nibioToken$(): Observable<string> {
    return this.#nibioTokenSubject.asObservable();
  }

  constructor() {
    super();

    this.#nibioTokenSubject.subscribe(token => (this.#nibioTokenValue = token));

    this.#isAuthenticatedSubject.subscribe(isAuth => {
      this.#isAuthenticatedValue = isAuth;
      if (isAuth) {
        this.startCheckAuthTimer();
      } else {
        this.stopCheckAuthTimer();
      }
    });
  }

  private getSignOutUrl(returnUrl = '/search'): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const routingUri = environment.signOutCallbackRoutingUri;
      if (!routingUri) {
        reject('Error: no signout url set. Check the configuration.');
      }
      const routing = 'clientCallbackRoutingUrl=' + routingUri;
      const url = this.#urlBackendService + 'signouturl?' + routing;
      if (this.#nibioTokenValue) {
        this.#httpClient.get<ISignOutResponse>(url, { headers: { state: returnUrl } }).subscribe(
          result => {
            if (result.isSuccess) {
              const redirectUri = result.url;
              if (redirectUri !== null) {
                resolve(redirectUri);
              } else {
                reject();
              }
            } else {
              reject();
            }
          },
          () => {
            this.somethingWentWrong();
            reject();
          },
        );
      } else {
        reject();
      } // interceptor add token to request headers
    });
  }

  /**
   * Unexpected error in login. Notify user and redirect to home
   */
  private notifyBeforeLogout(autoLogoutTimestamp: number) {
    const config: ModalOptions = {
      backdrop: true,
      class: 'modal-md',
      ignoreBackdropClick: true,
      keyboard: false,
    };

    if (!this.bsModalRef) {
      this.bsModalRef = this.#ngxBsModalService.show(NotifyBeforeLogoutComponent, config);
      (this.bsModalRef.content as NotifyBeforeLogoutComponent).setLogoutTimestamp(autoLogoutTimestamp);

      this.bsModalRef.onHide.subscribe((result: string) => {
        this.bsModalRef = undefined;

        // Decide which action to take when user interact with NotifyBeforeLogout
        if (result === 'goToHome') {
          void this.#router.navigateByUrl('/');
        } else if (result === 'login') {
          this.signIn(location.origin + location.pathname);
        } else if (result === 'continue') {
          this.renewToken();
        } else if (result === 'logout') {
          this.signOut(location.origin + location.pathname);
        }
      });
    }
  }

  /**
   * Parse received responseobject from backend gettoken and set local storage
   *
   * @param  {IGetTokenResponse}  response    Response object from "GetToken"
   */
  private saveTokenResponseInLocalStorage(response: IGetTokenResponse): void {
    if (response.nibioToken != null) {
      this.#isAuthenticatedSubject.next(true);
      const idTokenJson = JSON.parse(response.nibioToken);
      this.#nibioTokenSubject.next(idTokenJson.nibioToken);

      // localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('nibioToken', idTokenJson.nibioToken);
    }
  }

  /**
   * Call backend to receive validated login URL for this session
   *
   * @return {Promise<string>}                The URL client must be redirected to for login
   */
  private signOutIfNotAuthenticated(response: IValidateTokenResponse) {
    const nowTimeStamp = new Date().getTime(); // Date.now()
    const nextTimeStamp = nowTimeStamp + environment.checkAuthIntervalInMillicecounds;
    const lastActivityTimeStamp =
      localStorage.getItem('lastActivityTimeStamp') != null
        ? parseInt(localStorage.getItem('lastActivityTimeStamp'), 10)
        : nowTimeStamp;
    const lastActivityTimeoutTimeStamp = lastActivityTimeStamp + environment.logoutIfInactiveInMillicecounds;
    const countDownMillisecounds = environment.countDownBeforeLogoutMillisecounds;
    /*
    console.log('Now: ' + new Date(nowTimeStamp).toLocaleTimeString() +
                 ' - Last activity: ' + new Date(lastActivityTimeStamp).toLocaleTimeString() +
                 ' - Token expires: ' + new Date(response.expirationTime).toLocaleTimeString() +
                 ' - Activity expires: ' + new Date(lastActivityTimeoutTimeStamp).toLocaleTimeString()
                );
*/
    // --- If token is invalid or expired then log out local ---
    if (!response.isValidUserToken && this.#isAuthenticatedValue) {
      // this.signOutLocal();
      this.notifyBeforeLogout(nowTimeStamp);
    } else if (
      response.expirationTime - nowTimeStamp < environment.checkAuthIntervalInMillicecounds ||
      response.expirationTime - nowTimeStamp < countDownMillisecounds
    ) {
      // --- Token expired: Notify user before logout if less than 2 check intervall before auto logout ---
      this.notifyBeforeLogout(nextTimeStamp);
    } else if (
      lastActivityTimeoutTimeStamp - nowTimeStamp < environment.checkAuthIntervalInMillicecounds ||
      lastActivityTimeoutTimeStamp - nowTimeStamp < countDownMillisecounds
    ) {
      // --- No activity: Notify user before logout if less than 2 check intervall before auto logout ---
      this.notifyBeforeLogout(nextTimeStamp);
    }
  }

  /**
   * Clear local login information
   *
   */
  private signOutLocal(): void {
    this.#isAuthenticatedSubject.next(false);
    this.#nibioTokenSubject.next(null);
    this.#userService.clearUserData();

    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    localStorage.removeItem('codeVerifier');
    localStorage.removeItem('nibioToken');
    localStorage.removeItem('lastActivityTimeStamp');
  }

  /**
   * Unexpected error in login. Notify user and redirect to home
   */
  private somethingWentWrong() {
    this.bsModalRef = this.#ngxBsModalService.show(SomethingWentWrongDialogComponent);
    this.#ngxBsModalService.onHide.subscribe(() => {
      void this.#router.navigateByUrl('/');
    });
  }

  /**
   * Start timer to check auth (configured interval)
   */
  private startCheckAuthTimer() {
    this.stopCheckAuthTimer();
    this.#interval = setInterval(() => {
      this.checkAuthAndSignOutIfNotAuthenticated();
    }, environment.checkAuthIntervalInMillicecounds);

    // -- Initial auth check in case token exist in the store ---
    // this.checkAuthAndSignOutIfNotAuthenticated();
  }

  /**
   * Stop timer to check auth status
   */
  private stopCheckAuthTimer() {
    if (this.#interval != null) {
      clearInterval(this.#interval);
    }
  }

  /**
   * Check login state
   *
   */
  checkAuthAndSignOutIfNotAuthenticated(): void {
    if (this.#isAuthenticatedValue) {
      const url = this.#urlBackendService + 'isUserAuthenticated';
      this.#httpClient.get<IValidateTokenResponse>(url).subscribe(response => {
        if (!response.isSuccess) {
          console.log(response.errorMessage);
        }
        this.signOutIfNotAuthenticated(response);
      });
    }
  }

  // @ts-expect-error Needs a rewrite, currently causes: TS7030: Not all code paths return a value
  getSignInUrl(returnUrl: string): Promise<string> {
    if (environment.signInCallbackRoutingUrl === null || environment.signInCallbackRoutingUrl === '') {
      console.log('Error: signInCallbackRoutingUrl not set! Check your configuration');
    }
    const url = this.#urlBackendService + 'signIn?clientCallbackRoutingUrl=' + environment.signInCallbackRoutingUrl;
    try {
      const stateHeader = new SignInState(returnUrl, this.#mapCanvasService.getCanvasHash);
      return new Promise<string>((resolve, reject) => {
        this.#httpClient
          .get<ISignInResponse>(url, {
            headers: {
              state: JSON.stringify(stateHeader),
            },
          })
          .subscribe(
            result => {
              if (result.isSuccess) {
                // --- Store codeVerifier from result. (Needed in getToken request later) ---
                localStorage.setItem('codeVerifier', result.codeVerifier);
                const redirectUri = result.authorizationRequestUri;
                if (redirectUri != null) {
                  resolve(redirectUri); // Resolve redirect URL
                } else {
                  reject(null);
                }
              } else {
                console.log('Error getSignInUrl(): ' + result.errorMessage);
                this.somethingWentWrong();
                reject(null);
              }
            },
            error => {
              console.log('Unexpected error when calling signIn(): ' + error.message);
              this.somethingWentWrong();
            },
          );
      });
    } catch (err) {
      console.log('Unexpected error in getSignInUrl(): ' + err.message);
      this.somethingWentWrong();
    }
  }

  renewToken(): void {
    try {
      const getTokenUrl = this.#urlBackendService + 'renewToken';
      this.#httpClient.get<IGetTokenResponse>(getTokenUrl).subscribe(
        response => {
          if (response.isSuccess && response.isValidToken) {
            // --- Parse token from response and save local storage ---
            this.saveTokenResponseInLocalStorage(response);
          } else {
            console.log(response.errorMessage ?? 'Backed failed when trying to receive token');
            this.somethingWentWrong();
          }
        },
        error => {
          console.log('Unexpected error when calling getToken():', error.message);
          this.somethingWentWrong();
        },
      );
    } catch (err) {
      console.log('Unexpected error in signInCallback():', err.message);
      this.somethingWentWrong();
    }
  }

  /**
   * When user click "login" -> Redirect to the login page
   */
  signIn(returnUrl: string): void {
    this.getSignInUrl(returnUrl).then(redirectUri => {
      this.#document.location.href = redirectUri;
    });
  }

  /**
   * After successfully login this callback is recived to notify user to get token
   *
   * @param  {string}          code    Unique code for this login request (received from ID porten)
   * @param {SignInState}      state
   */
  signInCallback(code: string, state: SignInState): void {
    try {
      if (code) {
        // --- Get codeVerifier received from signIn ---
        const codeVerifier = localStorage.getItem('codeVerifier');
        if (!codeVerifier) {
          console.log('Unable to request token. Code verification not available!');
          this.somethingWentWrong();
        } else {
          const getTokenUrl =
            this.#urlBackendService +
            'getToken?clientCallbackRoutingUrl=' +
            environment.signInCallbackRoutingUrl +
            '&code=' +
            code +
            '&codeVerifier=' +
            codeVerifier;

          this.#httpClient.get<IGetTokenResponse>(getTokenUrl).subscribe(
            response => {
              localStorage.setItem(AppConfig.KEY_SIGNIN_RETURNURL, state.returnUrl);
              let redirectUrl = state.returnUrl;

              if (response.isSuccess && response.isValidToken) {
                // --- Parse token from response and save local storage ---
                this.saveTokenResponseInLocalStorage(response);
                if (!response.userExists) {
                  redirectUrl = '/bruker-registrering';
                } else {
                  this.#userService.refreshUserData();
                }
              } else {
                console.log(response.errorMessage ?? 'Backed failed when trying to receive token');
                this.somethingWentWrong();
              }

              if (state.canvasHash) {
                this.#mapCanvasService.setHash(state.canvasHash);
              }

              void this.#router.navigateByUrl(redirectUrl, {
                replaceUrl: true,
              });
            },
            error => {
              console.log('Unexpected error when calling getToken():', error.message);
              this.somethingWentWrong();
            },
          );
        }
      }
    } catch (err) {
      console.log('Unexpected error in signInCallback():', err.message);
      this.somethingWentWrong();
    }
  }

  signOut(returnUrl = '/search'): void {
    this.getSignOutUrl(returnUrl).then(
      redirectUri => {
        this.#document.location.href = redirectUri;
      },
      error => {
        if (error) {
          console.log(error);
        }
        this.signOutLocal();
        this.#document.location.href = returnUrl;
      },
    );
  }

  signOutCallback(state: string): void {
    const signOutUrl = this.#urlBackendService + 'signout';
    this.#httpClient.get<ISignOutResponse>(signOutUrl).subscribe(
      response => {
        if (response.isSuccess) {
          // if(response.isSuccess && response.userWasSignedOut) {
          this.signOutLocal();
          void this.#router.navigateByUrl(state, {
            replaceUrl: true,
            state: { returnUrl: state },
          });
        } else {
          this.somethingWentWrong();
        }
      },
      error => {
        console.log('Unexpected error when calling signOut():', error.message);
        this.somethingWentWrong();
      },
    );
  }
}

export type IGetTokenResponse = {
  errorMessage: string;
  idToken: string;
  isSuccess: boolean;
  isValidToken: boolean;
  nibioToken: string;
  refreshToken: string;
  userData: UserData;
  userExists: boolean;
  userIdentifier: string;
};

export type ISignInResponse = {
  authorizationRequestUri: string;
  codeVerifier: string;
  errorMessage: string;
  isSuccess: boolean;
};

export type ISignOutResponse = {
  errorMessage: string;
  isSuccess: boolean;
  url: string;
  userWasSignedOut: boolean;
};

export type IValidateTokenResponse = {
  errorMessage: string;
  expirationTime: number;
  isSuccess: boolean;
  isValidUserToken: boolean;
  userData: UserData;
};

export class SignInState {
  canvasHash: string;
  returnUrl: string;

  constructor(url: string, hash: string) {
    this.returnUrl = url;
    this.canvasHash = hash;
  }
}
