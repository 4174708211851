<div class="modal-header">
  <h4 class="modal-title pull-left">Logg ut</h4>
</div>
<div class="modal-body">
  @if (isAuthenticated$ | async; as isAuthenticated) {
    <div>
      Du vil snart bli logget ut av gårdskart fordi du ikke har gjort noe på en stund. Dette gjør vi for å beskytte dine
      opplysninger i tilfelle du har glemt å logge ut.<br /><br />
      @if (logoutRemainingSecounds) {
        Du blir automatisk logget ut om {{ logoutRemainingSecounds }} sekunder.
      }
    </div>
  } @else {
    <div>Du var stille en stund og har blitt logget ut i mellomtiden.</div>
  }
</div>
<div class="modal-footer">
  <div class="d-flex gap-2 justify-content-end">
    @if (isAuthenticated$ | async; as isAuthenticated) {
      <button (click)="continue()" class="btn btn-gk-warning" type="button">Forbli innlogget</button>
      <button (click)="logout()" class="btn btn-gk-warning" type="button">Logg ut</button>
    } @else {
      <button (click)="login()" class="btn btn-gk-warning" type="button">Logg inn på nytt</button>
      <button (click)="close()" class="btn btn-gk-warning" type="button">Fortsett</button>
    }
  </div>
</div>
