import { Component, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

/**
 * @title Login popup dialog
 */
@Component({
  selector: 'gk-something-went-wrong',
  standalone: true,
  styleUrls: ['something-went-wrong.dialog.scss'],
  templateUrl: 'something-went-wrong.dialog.html',
})
export class SomethingWentWrongDialogComponent {
  bsModalRef = inject(BsModalRef);

  hide() {
    this.bsModalRef.hide();
  }
}
