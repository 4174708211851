import { AsyncPipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/internal/Observable';
import { AuthProvider } from 'src/app/core/providers/IAuthProvider';

/**
 * @title Login popup dialog
 */
@Component({
  imports: [AsyncPipe],
  selector: 'gk-notify-before-logout-dialog',
  styleUrls: ['notify-before-logout.dialog.scss'],
  templateUrl: 'notify-before-logout.dialog.html',
})
export class NotifyBeforeLogoutComponent implements OnDestroy, OnInit {
  #authProvider = inject(AuthProvider);
  #interval: NodeJS.Timeout | undefined;
  #logoutTimestamp: Date;
  #modalService = inject(BsModalService);

  bsModalRef = inject(BsModalRef);
  isAuthenticated$: Observable<boolean>;
  logoutRemainingSecounds = 0;

  close() {
    this.#modalService.setDismissReason('close');
    this.bsModalRef.hide();
  }

  continue() {
    this.#modalService.setDismissReason('continue');
    this.bsModalRef.hide();
  }

  goToHome() {
    this.#modalService.setDismissReason('goToHome');
    this.bsModalRef.hide();
  }

  login() {
    this.#modalService.setDismissReason('login');
    this.bsModalRef.hide();
  }

  logout() {
    this.#modalService.setDismissReason('logout');
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    clearInterval(this.#interval);
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.#authProvider.isAuthenticated$;
  }

  reduceRemainingTime() {
    this.logoutRemainingSecounds = this.logoutRemainingSecounds - 1;
    if (this.logoutRemainingSecounds < 1) {
      this.#authProvider.signOut(location.origin + location.pathname);
      clearInterval(this.#interval);
    }
  }

  setLogoutTimestamp(stamp: number) {
    const now = new Date();
    this.#logoutTimestamp = new Date(stamp);
    this.logoutRemainingSecounds = Math.floor((this.#logoutTimestamp.getTime() - now.getTime()) / 1000);
    this.#interval = setInterval(() => {
      this.reduceRemainingTime();
    }, 1000);
  }
}
